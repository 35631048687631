import React from "react";
import { useTranslation } from "react-i18next";
import DataTableContainer from "../../Common/DataTableContainer";

const OrderTrackings = ({ trackings }) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Admin")}</span>,
      selector: (row) => row.user || "-",
      grow: 1,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Action")}</span>,
      selector: (row) => row.action || "-",
      // grow: 1,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Description")}</span>,
      selector: (row) => {
        return (
          <div className="py-3">
            <ul className="list-unstyled mb-0">
              {row.body ? Object.entries(row.body)
                    .map(([key, value]) => {
                      return (
                        <li key={key} className="text-capitalize">
                          {" -- "}
                          {key} : {value}
                        </li>
                      );
                    })
                : "-"}
            </ul>
          </div>
        );
      },
      wrap: true,
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Made at")}</span>,
      selector: (row) => row.created_at,
    },
  ];
  return <DataTableContainer noPagination columns={columns} data={trackings} />;
};

export default OrderTrackings;
