import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row } from 'reactstrap'
import { getAllPrintersAction, getFoodDataAction } from '../../../../redux/actions/foodActions'
import SelectFile from '../../../Common/SelectFile'
import CustomLabel from '../../../Custom/CustomLabel'
import CustomOption from '../../../Custom/CustomOption'
import { errorMessage } from '../../../../helpers/errorMessage'
import { useBranches, useGetFoodUnits } from '../../../../hooks/useQueryHelpers'
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query'
import { foodApi } from '../../../../apis/Food/api'

const Sidebar = ({ initialValues, register, watch, setValue, image, setImage, foodType, errors, menuImg, setMenuImg }) => {

    const [checkedCategories, setCheckedCategories] = useState([]);

    const { categories: {list: categories}, printers: {list: printers}} = useSelector(state => state.food);

    const { data: layouts } = useQuery(['layouts'], foodApi.layouts.getAll);

    const { branches: selectedBranches } = watch();

    const dispatch = useDispatch();

    const handleCheckChange = (id) => {
        if (checkedCategories.includes(id)) {
            setCheckedCategories(prev => prev.filter(typeId => typeId !== id));
        } else {
            setCheckedCategories(prev => ([
                ...prev,
                id
            ]))
        }
    }

    const { branches } = useBranches();

    const { units: { list: foodUnits }} = useGetFoodUnits({
        page_size: 'all'
    });

    const isEntry = (id) => {
        return categories.find(item => item.id === id)?.is_entrees;
    }

    const categoryDisabled = (id) => {
        return foodType === 8 && !isEntry(id);
    }

    useEffect(() => {
        setValue('categories', checkedCategories);
    }, [checkedCategories])

    useEffect(() => {
        if (initialValues?.file) {
            setImage({
                id: initialValues?.file?.id,
                name: initialValues?.file?.name,
                url: initialValues?.file?.link,
                file: null
            })
        }
        if (initialValues?.file_menu) {
            setMenuImg({
                id: initialValues?.file_menu?.id,
                name: initialValues?.file_menu?.name,
                url: initialValues?.file_menu?.link,
                file: null
            })
        }
    }, [initialValues])

    useEffect(() => {
        if (initialValues) {
            setCheckedCategories(initialValues.categories?.map(cat => cat.id));
            setValue('categories', initialValues.categories?.map(cat => cat.id));
            setValue('branches', initialValues.branches?.map(branch => branch.id));
            setValue('printer_id', initialValues.printer_id);
            setValue('layout_id', initialValues.layout_id);
            setValue('food_unit_id', initialValues.food_unit?.id);
        }
    }, [initialValues, printers, branches, layouts, foodUnits])

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'categories',
            params: {
                page_size: 'all'
            }
        }))
        dispatch(getAllPrintersAction());
    }, [])

    useEffect(() => {
        if (categories?.length && checkedCategories) {
            if (foodType === 8 && checkedCategories.find(item => !isEntry(item))) {
                setCheckedCategories(prev => prev.filter(item => isEntry(item)));
            }
        }
    }, [foodType, checkedCategories, categories])

  return (
    <div>
        <Row>
            <Card className='p-3'>
                <CustomLabel>Image</CustomLabel>
                <SelectFile 
                    selectedFiles={image}
                    setSelectedFiles={setImage}
                    type='image'
                />
                {!!image.url &&
                    <div className="form-check form-switch mt-3">
                        <input 
                            {...register('is_image_transparent')} 
                            type="checkbox" 
                            className="form-check-input fs-16" 
                            role="switch" 
                            id="is_image_transparent" 
                            disabled={!image.url} 
                        />
                        <CustomLabel className="form-check-label" htmlFor="is_image_transparent">Is transparent</CustomLabel>
                    </div>
                }
            </Card>
        </Row>

        <Row>
            <Card className='p-3'>
                <CustomLabel>Menu Image</CustomLabel>
                <SelectFile 
                    selectedFiles={menuImg}
                    setSelectedFiles={setMenuImg}
                    type='image'
                />
                {!!menuImg.url &&
                    <div className="form-check form-switch mt-3">
                        <input 
                            {...register('is_menu_image_transparent')} 
                            type="checkbox" 
                            className="form-check-input fs-16" 
                            role="switch" 
                            id="is_menu_image_transparent" 
                            disabled={!menuImg.url} 
                        />
                        <CustomLabel className="form-check-label" htmlFor="is_menu_image_transparent">Is transparent</CustomLabel>
                    </div>
                }
            </Card>
        </Row>

        <Row>
            <Card className='p-3'>

                <CustomLabel>Categories</CustomLabel>

                <div className='mb-3'>
                    {errorMessage(errors, 'categories')}
                </div>
                
                {categories.map(category => (
                    <div
                        className="form-check form-check-primary my-1"
                        key={category.id}
                    >
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${category.name}-${category.id}`}
                            checked={checkedCategories.includes(category.id)}
                            onChange={(e) => handleCheckChange(category.id)}
                            disabled={categoryDisabled(category.id)}
                        />
                        <CustomLabel className='form-check-label' htmlFor={`${category.name}-${category.id}`}>{category.name}</CustomLabel>
                    </div>
                ))}
            </Card>
        </Row>

        <Row>
            <Card className='p-3'>
                <CustomLabel>Printer</CustomLabel>
                <select
                    className='form-select'
                    {...register('printer_id')}
                >   
                    <CustomOption value="">Select printer...</CustomOption>
                    {printers.map(printer => (
                        <option key={printer.id} value={printer.id}>{printer.name}</option>
                    ))}
                </select>
            </Card>

            <Card className='p-3'>
                <CustomLabel>Branches</CustomLabel>
                <Select 
                    value={branches.filter(branch => selectedBranches?.includes(branch.id)).map(branch => ({
                        label: branch.name,
                        value: branch.id
                    }))}
                    options={branches.map(branch => ({
                        label: branch.name,
                        value: branch.id
                    }))}
                    onChange={(values) => setValue('branches', values.map(value => value.value))}
                    menuPortalTarget={document.body}
                    isMulti
                />
            </Card>

            <Card className='p-3'>
                <CustomLabel>Unit</CustomLabel>
                <select
                    className='form-select'
                    {...register('food_unit_id')}
                >   
                    <CustomOption value="">Select...</CustomOption>
                    {foodUnits.map(unit => (
                        <option key={unit.id} value={unit.id}>{unit.name}</option>
                    ))}
                </select>
            </Card>

            <Card className='p-3'>
                <CustomLabel>Layout</CustomLabel>
                <select
                    className='form-select'
                    {...register('layout_id')}
                >   
                    {layouts?.data?.data.map(layout => (
                        <option key={layout.id} value={layout.id}>{layout.name}</option>
                    ))}
                </select>
            </Card>

        </Row>

        <Row>
            <Card className='p-3'>
                <div className='d-flex gap-4'>
                    
                    <div className="form-check form-switch">
                        <input {...register('is_menu')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_menu" />
                        <CustomLabel className="form-check-label" htmlFor="is_menu">Menu</CustomLabel>
                    </div>

                    <div className="form-check form-switch">
                        <input {...register('is_menu_slider')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_menu_slider" />
                        <CustomLabel className="form-check-label" htmlFor="is_menu_slider">Slider</CustomLabel>
                    </div>
                </div>
            </Card>
        </Row>

        <Row>
            <Card className='p-3'>
                <div className='d-flex gap-4'>
                    
                    <div className="form-check form-switch">
                        <input {...register('active')} className="form-check-input fs-16" type="checkbox" role="switch" id="active" />
                        <CustomLabel className="form-check-label" htmlFor="active">Active</CustomLabel>
                    </div>

                    <div className="form-check form-switch">
                        <input {...register('best')} className="form-check-input fs-16" type="checkbox" role="switch" id="best" />
                        <CustomLabel className="form-check-label" htmlFor="best">Best</CustomLabel>
                    </div>
                </div>
            </Card>
        </Row>

        <Row>
            <Card className='p-3'>
                <div className='d-flex gap-4'>
                    <div className="form-check form-switch">
                        <input {...register('takeaway')} className="form-check-input fs-16" type="checkbox" role="switch" id="takeaway" />
                        <CustomLabel className="form-check-label" htmlFor="takeaway">Takeaway</CustomLabel>
                    </div>

                    <div className="form-check form-switch">
                        <input {...register('restaurent')} className="form-check-input fs-16" type="checkbox" role="switch" id="restaurant" />
                        <CustomLabel className="form-check-label" htmlFor="restaurant">Restaurant</CustomLabel>
                    </div>
                </div>
            </Card>
        </Row>

    </div>
  )
}

export default Sidebar