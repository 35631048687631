import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import PosForm from '../../../Components/Settings/POS/Form'

const CreatePOS = () => {
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="POS" title="Create" to="settings/pos"/>
      <PosForm 
        type="create"
      />
    </PageContainer>
  )
}

export default CreatePOS