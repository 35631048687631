import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, ModalBody, Row } from "reactstrap";
import { generatePassword } from "../../../helpers/generatePassword";
import useActions from "../../../hooks/useActions";
import TogglePassword from "../../Common/TogglePassword";
import CustomButton from "../../Custom/CustomButton";
import { errorMessage } from "../../../helpers/errorMessage";
import CustomOption from "../../Custom/CustomOption";
import { setFormErrors } from "../../../helpers/setFormErrors";
import CustomLabel from "../../Custom/CustomLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import adminSchema from "../../../schemas/adminSchema";
import { formatExample } from "../../../helpers/formatExample";
import { useGetPOS } from "../../../hooks/useQueryHelpers";

const AdminFormModal = ({
  initialValues,
  type,
  roles,
  createNewAdmin,
  updateAdmin,
  toggleModal,
  branches
}) => {
  const { register,  handleSubmit, setValue, formState: { errors }, setError, watch } = useForm({
    defaultValues: type === "Update" ? (() => {
      const { id, created_at, groups, permissions, phone, is_guest, last_seen, addressList, block_mail, ...rest } = initialValues;
      return rest
    })() : {},
    resolver: yupResolver(adminSchema),
  });

  console.log('initialValues', initialValues);

  const { isLoading, onLoading, onError, onFinally } = useActions();

  const { is_cs, is_pos } = watch();

  const { pos: { list: userSections} } = useGetPOS({
    page_size: 'all'
  });

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [image, setImage] = useState({
    url: '',
    file: null
})

  const submitForm = (data) => {

    onLoading();

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.set(key, data[key])
    })

    const file = image.file
    if (file) {
        const blob = new Blob([file], {type: file.type})
        formData.append('image', blob, file.name);
    }

    if (type === "Create") {
      createNewAdmin(data, {
        onSuccess() {
          toggleModal();
        },
        onError(error) {
          onError(error);
          setFormErrors(error, setError);
        },
      });
    } else if (type === "Update") {
      updateAdmin(initialValues.id, data, {
        onSuccess() {
          toggleModal();
        },
        onError(error) {
          onError(error);
          setFormErrors(error, setError);
        },
      });
    }
  };

  useEffect(() => {
    if (type === "Update") {
      setValue("role", initialValues.role);
      setValue("branche_id", initialValues.branche_id);
      setValue("user_section_id", initialValues.user_section_id);
    }
  }, [initialValues, type, roles, userSections]);

  useEffect(() => {
    if (initialValues?.icon) {
        setImage({
            id: initialValues.file?.id,
            url: initialValues.icon,
            file: null,
        })
    }
}, [initialValues])

  const setPassword = () => {
    const password = generatePassword();
    setPasswordVisible(true);
    setValue("password", password);
  };

  useEffect(() => {
    if (is_cs && is_pos) {
      setValue("is_cs", false);
    }
  }, [is_pos])

  useEffect(() => {
    if (is_pos && is_cs) {
      setValue('is_pos', false);
    }
  }, [is_cs])

  return (
    <ModalBody>
      <form onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-3">

          <Col lg={12}>
            <CustomLabel htmlFor="email">Email</CustomLabel>
            <input
              type="text"
              name="email"
              {...register("email")}
              className="form-control"
              id="email"
              placeholder={formatExample("admin@gmail.com")}
            />
            {errorMessage(errors, "email")}
          </Col>

          <Col lg={12}>
            <CustomLabel htmlFor="name">Name</CustomLabel>
            <input
              name="name"
              {...register("name")}
              type="text"
              className="form-control"
              id="name"
              placeholder={formatExample("Philip Matt")}
            />
            {errorMessage(errors, "name")}
          </Col>

          <Col lg={12}>
            <CustomLabel htmlFor="username">Username</CustomLabel>
            <input
              name="name"
              {...register("username")}
              type="text"
              className="form-control"
              id="username"
              placeholder={formatExample("Philip_Matt")}
            />
            {errorMessage(errors, "username")}
          </Col>

          <Col lg={12}>
            <CustomLabel htmlFor="Role">Role</CustomLabel>
            <select {...register("role")} className="form-select" id="Role">
              <CustomOption value="">None</CustomOption>
              {roles.map((role) => {
                return (
                  <CustomOption key={role.id} value={role.name}>
                    {role.name}
                  </CustomOption>
                );
              })}
            </select>
            {errorMessage(errors, "role")}
          </Col>

          <Col lg={12}>
            <CustomLabel htmlFor="branch">Branch</CustomLabel>
            <select {...register("branche_id")} className="form-select" id="branch">
              <CustomOption value="">None</CustomOption>
              {branches.map((branch) => {
                return (
                  <CustomOption key={branch.id} value={branch.id}>
                    {branch.name}
                  </CustomOption>
                );
              })}
            </select>
            {errorMessage(errors, "branche_id")}
          </Col>

          <Col lg={12}>
              <CustomLabel>User Section</CustomLabel>
              <select
                  className="form-select"
                  {...register("user_section_id")}
              >
                  <CustomOption value="">Select...</CustomOption>
                  {userSections.map(section => (
                      <option key={section.id} value={section.id}>{section.name}</option>
                  ))}
              </select>
          </Col>


          <Col lg={12}>
            <CustomLabel htmlFor="password">Password</CustomLabel>

            <div className="d-flex align-items-center gap-1">
              <input
                name="password"
                {...register("password")}
                type={passwordVisible ? "text" : "password"}
                className="form-control"
                id="password"
              />

              <button
                type="button"
                className="btn btn-primary btn-sm"
                title="Generate password"
                onClick={setPassword}
              >
                <i className="ri-key-2-line fs-15"></i>
              </button>

              <TogglePassword
                visible={passwordVisible}
                setVisible={setPasswordVisible}
              />
            </div>
            {errorMessage(errors, "password")}
          </Col>

                 
          <Col lg={12} className="hstack gap-2 flex-wrap">

            <div className="form-check form-switch">
                  <input {...register('is_dash')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_dash" />
                  <CustomLabel className="form-check-label" htmlFor="is_dash">Dashboard</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('is_kitchen')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_kitchen" />
                  <CustomLabel className="form-check-label" htmlFor="is_kitchen">Kitchen</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('is_delivery')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_delivery" />
                  <CustomLabel className="form-check-label" htmlFor="is_delivery">Delivery</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('is_client_screen')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_client_screen" />
                  <CustomLabel className="form-check-label" htmlFor="is_client_screen">Client Screen</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('is_pos')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_pos" />
                  <CustomLabel className="form-check-label" htmlFor="is_pos">POS</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('is_cs')} className="form-check-input fs-16" type="checkbox" role="switch" id="is_cs" />
                  <CustomLabel className="form-check-label" htmlFor="is_cs">Call center</CustomLabel>
              </div>

          </Col>

          <Col lg={12}>
            <CustomButton
              type="submit"
              className="btn btn-success"
              isLoading={isLoading}
            >
              Save
            </CustomButton>
          </Col>
        </Row>
      </form>
    </ModalBody>
  );
};

export default AdminFormModal;
