import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import { getOnePosApi } from '../../../apis/Settings/PosApi'
import PosForm from '../../../Components/Settings/POS/Form'

const UpdatePOS = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading } = useQuery(['one-pos'], () => getOnePosApi(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="POS" title="Update" to="settings/pos"/>
      {initialValues &&
        <PosForm 
          type="update"
          initialValues={initialValues}
        />
      }
    </PageContainer>
  )
}

export default UpdatePOS