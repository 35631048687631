import axiosInstance from "..";

export const getAllPosApi = (query) => {
  return axiosInstance.get(`/user_sections`, { params: query });
};

export const getOnePosApi = (id) => {
  return axiosInstance.get(`/user_sections/${id}`);
};

export const createPosApi = (body) => {
  return axiosInstance.post(`/user_sections`, body);
};

export const updatePosApi = (id, body) => {
  return axiosInstance.post(`/user_sections/${id}`, body);
};

export const deletePosApi = (ids) => {
  return axiosInstance.delete("/user_sections", {
    data: {
      ids,
    },
  });
};
