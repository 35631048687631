import axiosInstance from "..";

export const foodApi = {
  menu: {
    getAll: (query) => {
      return axiosInstance.get("/foods", {
        params: query,
      });
    },

    getAllMinimized: (query) => {
      return axiosInstance.get("/foods/names", {
        params: query,
      });
    },

    getOne: (id, lang = 'en') => {
      return axiosInstance.get(`/foods/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/foods?lang=${lang}`, data);
    },

    clone: (data) => {
      return axiosInstance.post(`/foods/clone`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/foods/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/foods", {
        data,
      });
    },

    addMultipleComponents: (data) => {
      return axiosInstance.post("/foods/updateMultiple", data);
    },

    file: {
      update: (id, data) => {
        return axiosInstance.post(`/foods/updateFile/${id}`, data);
      },
    },

    priceSizeImage: {
      update: (id, data) => {
        return axiosInstance.post(`/foods/updatePriceImage/${id}`, data);
      },
      delete: (id) => {
        return axiosInstance.delete(`/foods/updatePriceImage/${id}`);
      },
    }
  },

  categories: {
    getAll: (query) => {
      return axiosInstance.get("/categories", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/categories/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/categories?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/categories/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/categories", {
        data,
      });
    },
  },

  entress: {
    getAll: () => {
      return axiosInstance.get("/foods/entrees");
    },
  },

  components: {
    getAll: (query) => {
      return axiosInstance.get("/components", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/components/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/components?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/components/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/components", {
        data,
      });
    },

  },

  componentItems: {
    getAll: (query) => {
      return axiosInstance.get("/component_items", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/component_items?component_id=${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/component_items?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/component_items/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/component_items", {
        data,
      });
    },
  },

  offers: {
    getAll: (query) => {
      return axiosInstance.get("/offers", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/offers/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/offers?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/offers/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/offers", {
        data,
      });
    },
  },

  allergy: {
    getAll: (query) => {
      return axiosInstance.get("/allergies", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/allergies/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/allergies?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/allergies/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/allergies", {
        data,
      });
    },
  },

  options: {
    getAll: (query) => {
      return axiosInstance.get("/options", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/options/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/options?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/options/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/options", {
        data,
      });
    },
  },

  sizes: {
    getAll: (query) => {
      return axiosInstance.get("/sizes", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/sizes/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/sizes?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/sizes/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/sizes", {
        data,
      });
    },
  },

  units: {
    getAll: (query) => {
      return axiosInstance.get("/food_units", {
        params: query,
      });
    },

    getOne: (id, lang = 'en') => {
      return axiosInstance.get(`/food_units/${id}?lang=${lang}`);
    },

    create: (data) => {
      return axiosInstance.post(`/food_units`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/food_units/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/food_units", {
        data,
      });
    },
  },

  doughAndBread: {
    getAll: (query) => {
      return axiosInstance.get("/breads", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/breads/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/breads?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/breads/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/breads", {
        data,
      });
    },
  },

  doneness: {
    getAll: (query) => {
      return axiosInstance.get("/cooking_levels", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/cooking_levels/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/cooking_levels?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/cooking_levels/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/cooking_levels", {
        data,
      });
    },
  },

  spiceLevels: {
    getAll: (query) => {
      return axiosInstance.get("/hot_levels", {
        params: query,
      });
    },

    getOne: (id, lang) => {
      return axiosInstance.get(`/hot_levels/${id}?lang=${lang}`);
    },

    create: (data, lang) => {
      return axiosInstance.post(`/hot_levels?lang=${lang}`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/hot_levels/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/hot_levels", {
        data,
      });
    },
  },

  vat: {
    getAll: (query) => {
      return axiosInstance.get("/taxes", {
        params: query,
      });
    },

    getOne: (id) => {
      return axiosInstance.get(`/taxes/${id}`);
    },

    create: (data) => {
      return axiosInstance.post('/taxes', data);
    },

    update: (id, data) => {
      return axiosInstance.post(`/taxes/${id}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/taxes", {
        data,
      });
    },
  },
  printers: {
    getAll: (query) => {
      return axiosInstance.get(`/printers`, {
        params: query
      });
    },
    getOne: (id, lang) => {
      return axiosInstance.get(`/printers/${id}?lang=${lang}`);
    },
    create: (data, lang) => {
      return axiosInstance.post(`/printers?lang=${lang}`, data);
    },
    update: (id, data, lang) => {
      return axiosInstance.post(`/printers/${id}?lang=${lang}`, data);
    },
    delete: (data) => {
      return axiosInstance.delete(`/printers`, {
        data,
      });
    },
    getPrintersSettings: () => {
      return axiosInstance.get('/printers/getset');
    },
  },
  foodTypes: {
    getAll: (query) => {
      return axiosInstance.get("/types", {
        params: query,
      });
    },
  },

  offerTypes: {
    getAll: (query) => {
      return axiosInstance.get("/offers/names", {
        params: query,
      });
    },
  },

  types: (type) => {
    return axiosInstance.get(`/types/${type}`);
  },

  sortItems: (data) => {
    return axiosInstance.post('/sortItems', data);
  },

  checkSlug: (type, data) => {
    return axiosInstance.post(`/${type}/checkSlug`, data);
  },

  layouts: {
    getAll: () => {
      return axiosInstance.get("/foods/layouts");
    },
  }
};
