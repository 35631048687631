import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'
import FoodUnitForm from '../../../Components/Food/Units/Form'
import { foodApi } from '../../../apis/Food/api'

const UpdateFoodUnit = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, refetch } = useQuery(['one-food-unit'], () => foodApi.units.getOne(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  const { language, setLanguage } = useFetchByLang(refetch);
  
  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Units" title="Update" to="food/units"/>
      {initialValues &&
        <FoodUnitForm 
          type="update"
          initialValues={initialValues}
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  )
}

export default UpdateFoodUnit