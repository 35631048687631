import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import FoodUnitForm from '../../../Components/Food/Units/Form'

const CreateFoodUnit = () => {
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Units" title="Create" to="food/units"/>
      <FoodUnitForm 
        type="create"
      />
    </PageContainer>
  )
}

export default CreateFoodUnit