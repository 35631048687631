import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Card, CardBody, Col, Row } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel'
import moment from 'moment'
import { downloadPdfLandscape } from '../../../helpers/pdfHelper'
import { reportRqBody } from '../../../helpers/requests/Reports/ReportRqBody'
import { reportsApiV2 } from '../../../apis/Reports/api'
import toastAlert from '../../../helpers/toastAlert'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useQuery } from '@tanstack/react-query'
import CustomButton from '../../Custom/CustomButton'
import Flatpickr from 'react-flatpickr'
import SearchUsers from './SearchUsers'
import PageContainer from '../../Layout/PageContainer'
import DisplayReport from '../../../pages/Reports/Reports/Display'
import Select from 'react-select'
import { objectFilter } from '../../../helpers/objectFilter'

const ReportsForm = () => {

    const { type: reportType } = useParams();

    const { register, handleSubmit, watch, setValue, getValues, control, reset } = useForm({
        defaultValues: {
            date_from: moment().startOf('month').toDate(),
            date_to: moment().endOf('month').toDate(),
        }
    });

    const [displayData, setDisplayData] = useState(null);

    const { isLoading, data } = useQuery(['report-data'], reportsApiV2.getReportData);

    const [isSubmitting, setIsSubmitting] = useState(null);

    const reportsData = data?.data?.data;

    const paymentMethods = reportsData?.payment_methods ?? [];
    const deliveryMethods = reportsData?.delivery_methods ?? [];
    const platforms = reportsData?.platforms ?? [];
    const drivers = reportsData?.drivers ?? [];
    const channels = reportsData?.channels ?? [];
    const admins = reportsData?.admins ?? [];
    const tables = reportsData?.tables ?? [];
    const zones = reportsData?.zones ?? [];
    const deliveries = reportsData?.deliveries ?? [];
    const branches = reportsData?.branches ?? [];

    const selectedBranches = watch('branche_id')?.map(({ value }) => value) ?? [];

    const handleDateChange = (date) => {
        const start = date[0]
        const end = date[1] ?? '' 
        reset({
            date_from: start,
            date_to: end
        })
      }

    const submitForm = async (data, type) => {
        try {
            const { date_from ,date_to, ...rest } = data;
            const dates = {
                date_from: date_from ? moment(date_from).format("YYYY-MM-DD HH:mm") : "",
                date_to: date_to ? moment(date_to).format("YYYY-MM-DD HH:mm") : "",
            }
            setIsSubmitting(type);
            const requestBody = objectFilter({
                ...reportRqBody(rest),
                ...dates,
                report_type: reportType,
            });

            if (type === 'export-csv') {
                const { data: { data: { link } } } = await reportsApiV2.reports.getExcelData(requestBody);
                window.open(link, "_blank");
            } else {
                if (type === 'export-pdf') {
                    const { data: { data: { link } } } = await reportsApiV2.reports.getPdfReport(requestBody);
                    window.open(link, "_blank");
                } else if (type === 'display') {
                    const { data: htmlResponse } = await reportsApiV2.reports.getData(requestBody);
                    setDisplayData(htmlResponse);
                }
            }
  
        } catch (error) {
            toastAlert({ type: 'error' });
        } finally {
            setIsSubmitting(null);
        }
    }

    if (displayData) return <DisplayReport data={displayData} setData={setDisplayData} />

  return (
    <PageContainer isLoading={isLoading || !data}>
        <form>
            <Card>
                <CardBody>

                    <Row className='gy-3'>
                        <Col lg={12}>

                            <div>
                                <CustomLabel className="fs-16">Payment Method</CustomLabel>
                            </div>

                            <div className='d-flex gap-3 border p-3'>
                                {paymentMethods.map(paymentMethod => (
                                    <div key={paymentMethod.id} className="form-check">
                                        <div className='form-check-option'>
                                            <input {...register(`payment.${paymentMethod.id}`)} className="form-check-input" type="checkbox" id={`payment-method-${paymentMethod.id}`}/>
                                            <CustomLabel className="form-check-label" htmlFor={`payment-method-${paymentMethod.id}`}>{paymentMethod.name}</CustomLabel>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div>
                                <CustomLabel className="fs-16">Delivery</CustomLabel>
                            </div>

                            <div className='d-flex gap-3 border p-3'>

                                {deliveryMethods.map(deliveryMethod => (
                                    <div key={deliveryMethod.id} className="form-check">
                                        <div className='form-check-option'>
                                            <input {...register(`delivery_type.${deliveryMethod.id}`)} className="form-check-input" type="checkbox" id={`delivery-method-${deliveryMethod.id}`}/>
                                            <CustomLabel className="form-check-label" htmlFor={`delivery-method-${deliveryMethod.id}`}>{deliveryMethod.name}</CustomLabel>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </Col>
                        
                        <Col lg={6}>
                            
                            <div>
                                <CustomLabel className="fs-16">Platform</CustomLabel>
                            </div>

                            <div className='d-flex gap-3 border p-3'>

                                {platforms.map(platform => (
                                    <div key={platform} className="form-check">
                                        <div className='form-check-option'>
                                            <input {...register(`platform.${platform}`)} className="form-check-input" type="checkbox" id={platform}/>
                                            <CustomLabel className="form-check-label" htmlFor={platform}>{platform}</CustomLabel>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </Col>

                        <Col lg={12}>
                            <Flatpickr
                                className="form-control"
                                options={{
                                    mode: "range",
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    time_24hr: true,
                                }}
                                value={[getValues('date_from'), getValues('date_to')]}
                                onChange={handleDateChange}
                            />
                        </Col>

                    </Row>

                    <Row className="mt-3 g-3">
                        <Col lg={6}>
                            <CustomLabel>Channel</CustomLabel>
                            <Controller 
                                name='channel_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={channels.map(channel => ({
                                            label: channel.name,
                                            value: channel.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>Delivery</CustomLabel>
                            <Controller 
                                name='delivery_company_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={deliveries.map(delivery => ({
                                            label: delivery.name,
                                            value: delivery.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>Admin</CustomLabel>
                            <Controller 
                                name='admin_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={admins.map(admin => ({
                                            label: admin.name,
                                            value: admin.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>Branch</CustomLabel>
                            <Controller 
                                name='branche_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={branches.map(branch => ({
                                            label: branch.name,
                                            value: branch.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>User</CustomLabel>
                            <SearchUsers control={control} />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>Driver</CustomLabel>
                            <Controller 
                                name='delivery_man_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={drivers.filter(driver => selectedBranches.includes(driver.branche_id)).map(driver => ({
                                            label: driver.name,
                                            value: driver.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>
                        
                        <Col lg={6}>
                            <CustomLabel>Area</CustomLabel>
                            <Controller 
                                name='delivery_zone_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={zones.filter(zone => selectedBranches.includes(zone.branche_id)).map(zone => ({
                                            label: zone.name,
                                            value: zone.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>Table</CustomLabel>
                            <Controller 
                                name='table_id'
                                control={control}
                                render={({ field: { onChange, value }}) => (
                                    <Select 
                                        value={value}
                                        options={tables.filter(table => selectedBranches.includes(table.branche_id)).map(table => ({
                                            label: table.name,
                                            value: table.id
                                        }))}
                                        onChange={(values) => onChange(values)}
                                        isMulti
                                    />
                                )}
                            />
                        </Col>
                    </Row>

                    <div className='d-flex gap-2 mt-4'>

                        <CustomButton
                            type="button"
                            className="btn-success"
                            onClick={handleSubmit((data) => submitForm(data, 'display'))}
                            isDisabled={isSubmitting !== null}
                            isLoading={isSubmitting === 'display'}
                        >
                            Display
                        </CustomButton>

                        <CustomButton
                            type="button"
                            className="btn-success"
                            onClick={handleSubmit((data) => submitForm(data, 'export-pdf'))}
                            isDisabled={isSubmitting !== null}
                            isLoading={isSubmitting === 'export-pdf'}
                        >
                            Export PDF
                        </CustomButton>

                        <CustomButton
                            type="button"
                            className="btn-success"
                            onClick={handleSubmit((data) => submitForm(data, 'export-csv'))}
                            isDisabled={isSubmitting !== null}
                            isLoading={isSubmitting === 'export-csv'}
                        >
                            Export CSV
                        </CustomButton>

                    </div>

                </CardBody>
            </Card>
        </form>
    </PageContainer>
  )
}

export default ReportsForm