import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useQuery } from '@tanstack/react-query';
import { foodApi } from '../../../apis/Food/api';
import LoadingComponent from '../../Common/Loading';
import FormComponentsTable from './Create/TabContents/Components/Table';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../Custom/CustomButton';
import toastAlert from '../../../helpers/toastAlert';

const ComponentModal = ({ isOpen, toggleModal, foodTypes, selectedItems, setSelectedItems }) => {

  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const [selectedComponent, setSelectedComponent] = useState(null);

  const [components, setComponents] = useState(null);

  const [data, setData] = useState(null);

  // Fetch Components call

  const { refetch: refetchComponents, isLoading, isRefetching } = useQuery(['food-components'], () => foodApi.components.getAll({
    types: foodTypes,
    items: true,
    lang: localStorage.getItem("I18N_LANGUAGE") || 'en'
  }), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: { data }}) {
      setComponents(data);
    }
  })

  const hasItems = (id) => data?.find(component => component.component_id === id)?.items?.some(item => item.selected);

  const submitForm = async () => {
    if (!selectedItems?.length) return
    try {
      setSubmitting(true);
      const requestBody = {
        food_ids: selectedItems,
        components: data.filter(component => component.items.some(item => item.selected)).map((component, index) => {
          const { name, ...componentData } = component;
          return {
            ...componentData,
            sort: componentData.sort ?? index + 1,
            items: component.items.filter(item => item.selected).map((item, index) => {
              const { selected, component_id, price_extra, default_qty, max_qty, ...rest } = item;
              return {
                ...rest,
                sort: item.sort ?? index + 1,
                ...(+component.single_select === 2 ? { 
                  default_qty, 
                  max_qty,
                  basic: +default_qty ? 1 : 0
                 } : { price_extra })
              }
            })      
          }
        }
        )
      }
      await foodApi.menu.addMultipleComponents(requestBody);
      toastAlert({ type: 'success' })
      setSelectedItems([]);
      toggleModal();
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
      setSubmitting(false);
    }
  }

  // Format data when components change

  useEffect(() => {
    
    if (components) {

        const newData =  components.map(component => {
            return {
              name: component.name,
              component_id: component.id,
              max_active: false,
              free_before_max: false,
              max_compo: '',
              single_select: '0',
              sort: null,
              desc: '',
              required: false,
              comment: false,
              items: component.items.map((item) => {

                const { icon, file, component_id, ...rest } = item;

                  return {
                    ...rest,
                    component_item_id: item.id,
                    image: icon,
                    basic: 0,
                    selected: false,
                    price: rest.price,
                    price_extra: rest.price_extra,
                    default_qty: 1,
                    max_qty: 1,
                    sort: null,
                    show_default: false,
                  }    

              })
            }
          }
        )

        setData(newData);
        setSelectedComponent(newData.find(component => component.items.some(item => item.selected))?.component_id || components[0]?.id);
    }
  }, [components, foodTypes])


  // Fetch data on food type change

  useEffect(() => {
    if (isOpen) {
      refetchComponents();
    }
  }, [foodTypes])


  const clearForm = () => {

  }

  return (
    <Modal
      centered 
      isOpen={isOpen}
      toggle={toggleModal}
      onClosed={clearForm}
      size={data?.length ? 'xl' : ''}
    >
      <ModalBody>
          
          <LoadingComponent loading={isLoading || isRefetching}>

            {data?.length > 0 ? 
                <>

                    <div className='d-flex flex-wrap'>
                      
                      <div className='selectable-tabs-container'>
                        {data?.map(component => (
                            <div
                              key={component.component_id} 
                              className={`selectable-tab heading-tab ${selectedComponent === component.component_id ? 'selected-tab' : ''} py-1 ${ hasItems(component.component_id) ? 'indicator' : ''}`}
                              onClick={() => setSelectedComponent(component.component_id)}
                            >
                              {component.name}
                            </div>
                          ))}

                      </div>
                    </div>

                    {data?.map(component => (
                        <FormComponentsTable
                            key={component.component_id}
                            component={component}
                            selectedComponent={selectedComponent}
                            data={component.items}
                            setData={setData}
                        />
                    ))}  

                    <div className='hstack gap-2 justify-content-end'>

                      <CustomButton
                        className="btn-danger"
                        onClick={toggleModal}
                      >
                        Cancel
                      </CustomButton>

                      <CustomButton
                        className="btn-success"
                        onClick={submitForm}
                        isLoading={submitting}
                      >
                          Save
                      </CustomButton>

                    </div>
                </>

                :

                <div className='d-flex justify-content-center align-items-center'>
                    {t('There are no available components for the selected food types.')}
                </div>
          }

          </LoadingComponent>
      </ModalBody>

    </Modal>
  )
}

export default ComponentModal