import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import toastAlert from "../../../helpers/toastAlert";
import { objectFilter } from "../../../helpers/objectFilter";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { createPosApi, updatePosApi } from "../../../apis/Settings/PosApi";
import { useBranches } from "../../../hooks/useQueryHelpers";
import CustomOption from "../../Custom/CustomOption";

const PosForm = ({ type, initialValues }) => {

  const { register, handleSubmit, reset } = useForm();

  const history = useHistory();

  const { isLoading, onLoading, onFinally } = useActions();

  const { branches } = useBranches();

  const submitForm = async (data) => {
    onLoading();
    try {
        const requestBody = objectFilter(data);
        if (type === "create") {
            await createPosApi(requestBody);
            toastAlert({ type: 'success' })
            history.push(getPathname('settings/pos'))
        } else {
            await updatePosApi(initialValues?.id, requestBody);
            toastAlert({ type: 'success' })
        }
    } catch (error) {
        toastAlert({ type: 'error' })
    } finally {
        onFinally();
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset(
        (() => {
          const { id, branche, ...rest } = initialValues;
          return rest;
        })()
      );
    }
  }, [initialValues, branches]);

  return (
    <Row>
        <Col lg={12}>
            <Card className="p-3">
                <Row className="gy-3">

                    <Col md={6}>
                        <CustomLabel>Name</CustomLabel>
                        <CustomInput
                            type="text"
                            {...register("name")}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>Branch</CustomLabel>
                        <select
                            className="form-select"
                            {...register("branche_id")}
                        >
                            <CustomOption value="">Select...</CustomOption>
                            {branches.map(branch => (
                                <option key={branch.id} value={branch.id}>{branch.name}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
    
            </Card>

            <div className="mb-3 inner-submit-button">
            <CustomButton 
                className="btn-success" 
                isLoading={isLoading}
                onClick={handleSubmit(submitForm)}
            >
                {type === "create" ? "Create" : "Update"}
            </CustomButton>
            </div>
        </Col>
    </Row>
  );
};

export default PosForm;
