import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useGetPOS } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import useModal from "../../../hooks/useModal";
import useActions from "../../../hooks/useActions";
import usePagination from "../../../hooks/usePagination";
import ListHeader from "../../../Components/Common/ListHeader";
import { deletePosApi } from "../../../apis/Settings/PosApi";
import PosTable from "../../../Components/Settings/POS/Table";

const Pos = () => {
  
  const { isLoading: isDeleting, onLoading: onDeleting, onError, onFinally } = useActions();

  const { isDisable, selectedItems, selectItems, getSelectedIds } = useSelectItems();
  
  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const { currentPage, pageSize, setPageSize, navigateToPage } = usePagination();

  const { isLoading, pos, deletePos, isRefetching, refetch } = useGetPOS({
    page: currentPage,
    page_size: pageSize,
  });

  const onDeleteClick = async () => {
    onDeleting();
    try {
      const ids = getSelectedIds();
      await deletePosApi({ ids });
      closeModal();
      deletePos(ids);
      refetch();
      selectItems([]);
    } catch (error) {
      onError();
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    refetch();
  }, [currentPage, pageSize]);

  return (
    <PageContainer isLoading={isLoading}>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="settings/pos/create"
          />
        </Col>
        <Col xs={12}>
          <PosTable
            isRefetching={isRefetching}
            data={pos}
            selectItems={selectItems}
            modal={isModalOpen}
            selectedItems={selectedItems}
            setModal={setIsModalOpen}
            onDeleteClick={onDeleteClick}
            isDeleting={isDeleting}
            setPageSize={setPageSize}
            navigateToPage={navigateToPage}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Pos;
