import React, { useEffect, useState } from 'react'
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import LoadingComponent from '../../../../../Common/Loading';
import FormComponentsTable from './Table';
import ComponentToggles from './Toggles';
import AddComponent from './AddComponent';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from '../../../../../../apis/Food/api';
import SortComponents from './Sort/SortComponents';
import { sortFoodComponents } from '../../../../../../helpers/sortFoodComponents';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import CustomNavLink from '../../../../../Custom/CustomNavLink';
import classnames from 'classnames';

const Components = ({ initialValues, foodType, setValue, register, watch }) => {

  const [selectedComponent, setSelectedComponent] = useState(null);

  const [components, setComponents] = useState(null);

  const [data, setData] = useState(null);

  const [activeTab, setActiveTab] = useState(1);

  // Fetch Components call

  const { refetch: refetchComponents, isLoading, isRefetching } = useQuery(['food-components'], () => foodApi.components.getAll({
    type_id: foodType,
    items: true,
    lang: localStorage.getItem("I18N_LANGUAGE") || 'en'
  }), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: { data }}) {
      setComponents(data);
    }
  })

  const hasItems = (id) => data?.find(component => component.component_id === id)?.items?.some(item => item.selected);

  // Format data when components change

  useEffect(() => {
    
    if (components) {

        const newData =  components.map(component => {
            const initialComponent = initialValues?.components?.find(item => item.component_id === component.id);
            return {
              name: component.name,
              component_id: component.id,
              max_active: initialComponent ? initialComponent?.max_active : false,
              free_before_max: initialComponent ? initialComponent?.free_before_max : false,
              max_compo: initialComponent ? initialComponent?.max_compo : '',
              single_select: initialComponent ? initialComponent?.single_select : '0',
              sort: initialComponent?.sort ?? null,
              desc: initialComponent?.desc ?? '',
              required: initialComponent?.required ?? false,
              comment: initialComponent?.comment ?? false,
              items: component.items.map((item) => {

                const initialVal = initialComponent?.items?.find(it => it.component_item_id === item.id);

                const { icon, file, component_id, ...rest } = item;

                  if (initialVal && initialValues?.type_id === foodType) return {
                    ...rest,
                    component_item_id: item.id,
                    image: icon,
                    basic: initialVal?.basic,
                    selected: !!initialVal,
                    price: initialVal?.price,
                    price_extra: initialVal?.price_extra,
                    default_qty: initialVal?.default_qty,
                    max_qty: initialVal?.max_qty,
                    sort: initialVal?.sort,
                    show_default: initialVal?.show_default ?? false,
                    changeable_price: initialVal?.changeable_price ?? false,
                  }

                  return {
                    ...rest,
                    component_item_id: item.id,
                    image: icon,
                    basic: 0,
                    selected: false,
                    price: rest.price,
                    price_extra: rest.price_extra,
                    default_qty: 1,
                    max_qty: 1,
                    sort: null,
                    show_default: false,
                    changeable_price: false
                  }    

              })
            }
          }
        )
        
        let sortedList = sortFoodComponents(newData);

        setData(sortedList);

        setSelectedComponent(sortedList.find(component => component.items.some(item => item.selected))?.component_id || components[0]?.id);
    }
  }, [components, initialValues, foodType])

  // Set data to form
  
  useEffect(() => {
    if (data) {
      setValue('components', data.filter(component => component.items.some(item => item.selected)).map((component, index) => {
        const { name, ...componentData } = component;
        return {
          ...componentData,
          sort: componentData.sort ?? index + 1,
          items: component.items.filter(item => item.selected).map((item, index) => {
            const { selected, component_id, price_extra, default_qty, max_qty, ...rest } = item;
            return {
              ...rest,
              sort: item.sort ?? index + 1,
              ...(+component.single_select === 2 ? { 
                default_qty, 
                max_qty,
                basic: +default_qty ? 1 : 0
               } : { price_extra })
            }
          })      
        }
      }
      )
      )
    }
  }, [data, setValue])

  // Fetch data on food type change

  useEffect(() => {
    if (foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'components')) {
          refetchComponents();
      } else {
        setValue('components', null);
      }
  }, [foodType])

  return (
    <div>
        <Nav tabs className="nav-tabs mb-3">
              <NavItem>
                  <CustomNavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === 1 })}
                      onClick={() => {
                        setActiveTab(1);
                      }}
                  >
                      List
                  </CustomNavLink>
              </NavItem>

              <NavItem>
                  <CustomNavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === 2 })}
                      onClick={() => {
                        setActiveTab(2);
                      }}
                  >
                      Sort List
                  </CustomNavLink>
              </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="text-muted">

              <TabPane tabId={1}>
              
                <LoadingComponent loading={isLoading || isRefetching}>

                  <div className='d-flex flex-wrap'>
                    
                    <div className='selectable-tabs-container'>
                      {data?.map(component => (
                          <div
                            key={component.component_id} 
                            className={`selectable-tab heading-tab ${selectedComponent === component.component_id ? 'selected-tab' : ''} py-1 ${ hasItems(component.component_id) ? 'indicator' : ''}`}
                            onClick={() => setSelectedComponent(component.component_id)}
                          >
                            {component.name}
                          </div>
                        ))}

                      <AddComponent 
                        setData={setData}
                        setSelectedComponent={setSelectedComponent}
                        foodType={foodType}
                      />
                    </div>
                  </div>

                  {data?.map(component => (
                      <FormComponentsTable
                          key={component.component_id}
                          component={component}
                          selectedComponent={selectedComponent}
                          setData={setData}
                          watch={watch}
                          addComponents
                      />
                  ))}

                  </LoadingComponent>
              </TabPane>
              
              <TabPane tabId={2}>
                  <SortComponents 
                      components={data}
                      setComponents={setData}
                  />
              </TabPane>

          </TabContent>

    </div>
  )
}

export default Components